const Media = Go.vue;

export default Media;

Media.methods.stateChanged = function(e = {}) {
  if (e.type === "play") {
    this.pauseOthers(e);
    return;
  }

  if (e.type === "pause") {
  }

  if (e.type === "timeupdate") {
    this.$store.state.media.muted = e.target.muted;
  }
};

Media.methods.canPlayVideo = function(e) {
  this.canplay = true;
};

Media.methods.userMenu = function(user) {
  this.view({
    title: `@${user.user}`,
    component: () => import("../creator/CreatorExploreInfo.vue"),
    class: "menu bottom CreatorExploreInfo",
    animation: "bottomIn",
    closeOnSwipe: "down",
    closeOutside: true,
    clean: true,
    header: false,
    creator: user,
    populate: true,
  });
};
