<template>
  <div :class="`TvMedia media-${media.id}`" :style="`--poster:url(${poster});`" :data-for="media.availableFor">
    <div class="TvMediaContent" @click="info = !info" :canplay="canplay">
      <span class="blurry"></span>
      <video
        v-bind="video"
        :muted="muted"
        @play="stateChanged"
        :poster="poster"
        @pause="stateChanged"
        @timeupdate="stateChanged"
        @canplay="canPlayVideo"
        controlsList="nodownload"
      ></video>
      <div class="TvMediaUser">
        <div class="TvMediaUserContent">
          <div class="TvMediaUserLeft">
            <UserAvatar :user="media.user" />
            <div class="UserName">
              <div>@{{ media.user.user }}</div>
              <div class="TvMediaDate" hide>{{ getTimeLapse(media) }}</div>
            </div>
          </div>
          <div class="TvMediaUserRight">
            <a class="TvUserOption" @click="userMenu(media.user)"><Icon name="ellipsis-v" /></a>
          </div>
        </div>
      </div>
      <div v-if="!media.canPost">
        <CreatorPostSubscribe v-if="post.availableFor === 'subscriber' && post.subcribeStatus !== 'active'" :post="post" />
        <CreatorPostUnlock v-else-if="post.availableFor === 'purchase' && post.subcribeStatus !== 'purchased'" :post="post" />
      </div>
    </div>
  </div>
</template>

<script>
import TvMedia from "./TvMedia.js";
export default {
  mixins: [TvMedia],
  props: ["media", "index"],
  components: {
    CreatorPostUnlock: () => import("../creator/CreatorPostUnlock.vue"),
    CreatorPostSubscribe: () => import("../creator/CreatorPostSubscribe.vue"),
  },
  data: function() {
    return {
      canplay: false,
      info: true,
      post: {
        _id: this.media._id,
        availableFor: this.media.availableFor,
        subcribeStatus: this.media.subcribeStatus,
        media: [this.media],
        user: this.media.user,
        price: this.media.price,
      },
      video: {
        src: this.media.location || this.media.Location || this.media.src,
        controls: true,
        autoplay: false,
        loop: false,
        playsinline: true,
      },
    };
  },
  created: async function() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 0.8,
    });
  },
  mounted: function() {
    this.video.autoplay = this.index === 0;
    this.observer.observe(this.$el);
  },
  beforeDestroy: function() {
    this.observer.disconnect();
  },
  methods: {
    pauseOthers: function() {
      const videos = document.querySelectorAll("video");
      videos.forEach((video) => {
        if (video !== this.$el.querySelector("video")) {
          video.pause();
        }
      });
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }

        if (this.playing === this.media.id) {
          return;
        }

        const video = target.querySelector("video");
        video.play();

        this.$store.state.media.playing = this.media.id;
      });
    },
  },
  computed: {
    muted: function() {
      return this.$store.state.media.muted;
    },
    playing: function() {
      return this.$store.state.media.playing;
    },
    isPrivate: function() {
      return this.media.availableFor === "subscriber" || this.media.availableFor === "purchase";
    },
    poster: function() {
      if (this.isPrivate && !this.media.canPost) {
        return this.media.fake && this.media.fake.location;
      }

      return this.media.thumb || (this.media.poster && this.media.poster.location);
    },
  },
};
</script>
